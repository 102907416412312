import React, { useState } from 'react';

import CheckIcon from '@videoblocks/react-icons/Check';
import CloseIcon from '@videoblocks/react-icons/Close';
import ModalDialog from '@videoblocks/shared-components/dist/mjs/components/Shared/ModalDialog';

type VisitorDownloadModalProps = {
  isOpen: boolean;
  onClose: () => void;
  stockItemTitle: string;
  smallImageUrl: string;
  mediumImageUrl: string;
};

type ModalContentProps = Pick<
  VisitorDownloadModalProps,
  'onClose' | 'stockItemTitle' | 'smallImageUrl' | 'mediumImageUrl'
>;

const CONTENT_TEXT = {
  headerText: 'Great Choice!',
  bodyText:
    'Subscribe to Storyblocks and gain access to this clip along with a vast library of over 2 million assets, including:',
  ctaText: 'Subscribe to Download',
};

const LIST_TEXT = [
  'HD & 4K footage, templates & motion backgrounds',
  'Music and sound effects',
  'Images, vectors and more',
];

export const ModalContent = ({
  onClose,
  stockItemTitle,
  smallImageUrl,
  mediumImageUrl,
}: ModalContentProps) => {
  const [mediumImageLoaded, setMediumImageLoaded] = useState(false);
  return (
    <div className="visitor-download-modal">
      <button
        onClick={onClose}
        className="border-none top-14 right-10 absolute"
      >
        <CloseIcon
          title="Close Modal"
          className="fill-current text-gray-700 w-4 h-4"
        />
      </button>
      <img
        className={`w-full rounded-2xl bg-gray-200 ${
          mediumImageLoaded ? 'hidden' : ''
        }`}
        src={smallImageUrl}
        alt={`${stockItemTitle} image`}
        style={{ aspectRatio: '16/9' }}
      />
      {mediumImageUrl && (
        <img
          className={`w-full rounded-2xl bg-gray-200 ${
            mediumImageLoaded ? '' : 'hidden'
          }`}
          src={mediumImageUrl}
          alt={`${stockItemTitle} image`}
          style={{ aspectRatio: '16/9' }}
          onLoad={() => setMediumImageLoaded(true)}
        />
      )}
      <div className="mt-6 px-5 leading-6">
        <div className="mb-4">{CONTENT_TEXT.bodyText}</div>
        <ul>
          {LIST_TEXT.map((text) => {
            return (
              <li className="list-none flex" key={text}>
                <div className="mr-3 transform rotate-12">
                  <CheckIcon className="w-3 h-3 fill-current text-blue-500" />
                </div>
                {text}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="text-center">
        <a
          href="/pricing"
          className="inline-block focus:outline-none focus-visible:ring focus-visible:ring-opacity-20 disabled:text-gray-500 transition-colors hover:no-underline disabled:bg-gray-200 hover:bg-yellow-600 focus:bg-yellow-600 bg-yellow-400 text-black focus-visible:ring-yellow-500 text-base leading-4 rounded-xl px-9 py-5 mb-6 mt-8 whitespace-nowrap font-semibold"
        >
          {CONTENT_TEXT.ctaText}
        </a>
        <div className="text-sm">
          Already have an account? <a href="/login">Log in</a>
        </div>
      </div>
    </div>
  );
};

const VisitorDownloadModal = ({
  isOpen,
  onClose,
  stockItemTitle,
  smallImageUrl,
  mediumImageUrl,
}: VisitorDownloadModalProps) => {
  const transitionDuration = 150;
  return (
    <ModalDialog
      id="visitor-download-modal"
      className="max-w-lg outline-none relative my-auto"
      contentLabel={CONTENT_TEXT.headerText}
      contentLabelClassName="text-center text-2xl text-gray-950 font-semibold mx-auto my-0"
      contentLabelContainerClassName="mb-4"
      closeButtonClassName="hidden"
      closeTimeoutMS={transitionDuration}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="bg-opacity-20 flex overflow-scroll"
      shouldCloseOnOverlayClick={true}
      topOffsetClass=""
    >
      <ModalContent
        smallImageUrl={smallImageUrl}
        mediumImageUrl={mediumImageUrl}
        onClose={onClose}
        stockItemTitle={stockItemTitle}
      />
    </ModalDialog>
  );
};

export default VisitorDownloadModal;
